:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: theme("colors.primary.800");
  --toastify-color-info: #3498db;
  --toastify-color-success: theme("colors.forestGreen.500");
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e95b5b;
  --toastify-color-transparent: rgb(255 255 255 / 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  // --toastify-toast-width: 320px;
  // --toastify-toast-background: #fff;
  // --toastify-toast-min-height: 64px;
  // --toastify-toast-max-height: 800px;
  --toastify-font-family: theme("fontFamily.display");

  // --toastify-z-index: 9999;

  // --toastify-text-color-light: #757575;
  // --toastify-text-color-dark: #fff;

  // //Used only for colored theme
  // --toastify-text-color-info: #fff;
  // --toastify-text-color-success: #fff;
  // --toastify-text-color-warning: #fff;
  // --toastify-text-color-error: #fff;

  // --toastify-spinner-color: #616161;
  // --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: theme("colors.primary.DEFAULT");

  // Used when no type is provided
  --toastify-color-progress-dark: theme("colors.secondary.DEFAULT");
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
