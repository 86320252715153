.banner-image-credit a {
  @apply underline;
}

.image-tiles img.m-0 {
  margin: 0;
}

.prose p > img {
  @apply mx-auto max-w-full object-contain;
}

.prose iframe {
  @apply mx-auto max-w-full;
}
