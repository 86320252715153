/* purgecss start ignore */

.prose li a,
.prose p a {
  @apply no-underline chunky-underline-primary-200 dark:chunky-underline-primary-500;
}

.prose blockquote {
  @apply pr-4;
}

html.dark {
  .prose ul a,
  .prose p a {
    @apply text-white;
  }
}

.tippy-box > .tippy-content {
  @apply font-display text-white;

  a {
    @apply bg-none text-white;
  }
}

.prose figcaption.flex > p {
  margin-top: 0;
  margin-bottom: 0;
}

.image-gallery,
.image-tiles {
  img,
  figure.m-0 {
    margin: 0 !important;
  }

  figcaption {
    margin-top: 0 !important;

    p {
      margin: 0;
    }
  }
}

iframe.flourish {
  width: 100%;
  height: 400px;

  @screen sm {
    height: 575px;
  }
}

.embed.embed-youtube iframe {
  margin: 0 auto;
}

/* stylelint-disable selector-class-pattern */
.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}
/* stylelint-enable selector-class-pattern */

/* purgecss end ignore */
