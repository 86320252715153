@import "./pages/article";
@import "./cms-content";

/*! purgecss start ignore */

:root {
  color-scheme: light dark;

  --nav-height: 60px;

  @screen md {
    --nav-height: 70px;
  }
}

.peach {
  --primary-color-default: theme("colors.peach.DEFAULT");
  --primary-color-50: theme("colors.peach.50");
  --primary-color-100: theme("colors.peach.100");
  --primary-color-200: theme("colors.peach.200");
  --primary-color-300: theme("colors.peach.300");
  --primary-color-400: theme("colors.peach.400");
  --primary-color-500: theme("colors.peach.500");
  --primary-color-600: theme("colors.peach.600");
  --primary-color-700: theme("colors.peach.700");
  --primary-color-800: theme("colors.peach.800");
  --primary-color-900: theme("colors.peach.900");
  --secondary-color-default: theme("colors.lime.DEFAULT");
  --secondary-color-50: theme("colors.lime.50");
  --secondary-color-100: theme("colors.lime.100");
  --secondary-color-200: theme("colors.lime.200");
  --secondary-color-300: theme("colors.lime.300");
  --secondary-color-400: theme("colors.lime.400");
  --secondary-color-500: theme("colors.lime.500");
  --secondary-color-600: theme("colors.lime.600");
  --secondary-color-700: theme("colors.lime.700");
  --secondary-color-800: theme("colors.lime.800");
  --secondary-color-900: theme("colors.lime.900");
}

.black-and-white {
  --primary-color-default: theme("colors.gray.500");
  --primary-color-50: theme("colors.gray.50");
  --primary-color-100: theme("colors.gray.100");
  --primary-color-200: theme("colors.gray.200");
  --primary-color-300: theme("colors.gray.300");
  --primary-color-400: theme("colors.gray.400");
  --primary-color-500: theme("colors.gray.500");
  --primary-color-600: theme("colors.gray.600");
  --primary-color-700: theme("colors.gray.700");
  --primary-color-800: theme("colors.gray.800");
  --primary-color-900: theme("colors.gray.900");
  --secondary-color-default: theme("colors.lime.DEFAULT");
  --secondary-color-50: theme("colors.lime.50");
  --secondary-color-100: theme("colors.lime.100");
  --secondary-color-200: theme("colors.lime.200");
  --secondary-color-300: theme("colors.lime.300");
  --secondary-color-400: theme("colors.lime.400");
  --secondary-color-500: theme("colors.lime.500");
  --secondary-color-600: theme("colors.lime.600");
  --secondary-color-700: theme("colors.lime.700");
  --secondary-color-800: theme("colors.lime.800");
  --secondary-color-900: theme("colors.lime.900");
}

html {
  &.font-bitter {
    --fonts-body: "BitterVariable";
  }

  &.font-inter {
    --fonts-body: "InterVariable";
  }

  &.font-proza-libre {
    --fonts-body: "Proza Libre";
  }
}

body {
  @apply font-body;
}

*:focus {
  outline: 1px dotted currentcolor;
}

button:focus {
  outline: 1px solid currentcolor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-display font-bold;
}

/* stylelint-disable selector-id-pattern */
#root,
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1 0 auto;
  }
}
/* stylelint-enable selector-id-pattern */

#nav {
  min-height: var(--nav-height);
}

/*! purgecss end ignore */

.container {
  @apply mx-auto w-full max-w-screen-lg p-4;
}

.container-p-0 {
  @apply mx-auto w-full max-w-screen-lg;
}

.min-h-screen-minus-nav {
  min-height: calc(100vh - var(--nav-height));
}

*:disabled {
  cursor: not-allowed;
}

html.dark {
  main img {
    filter: brightness(0.8) contrast(1.2);
  }
}
